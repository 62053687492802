import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { TagsComponent } from "../tags.component";
import { CategoriesComponent } from "../categories.component";
import { StatusComponent } from "../status.component";
import { TagsService } from "../tags.service";
import { StatusService } from "../status.service";
import { CategoriesService } from "../categories.service";
import { FormControl, FormGroup } from "@angular/forms";
import { DateHelper, Debounce, Helpers } from "../../../services/helpers";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs/index";
import { SocialSiteSelectComponent } from "../../../components/social-site-select/social-site-select.component";
import { BASE_DATE_FORMAT, Configs } from "../../../configs/configs";
import { OrganizationItem } from "~/src/app/components/organization-select/organization.interfaces";
import { Category } from "../template.interface";
import * as moment from "moment";
import { LanguageService } from "../../../services/language.service";
import { OrganizationController } from "~/src/app/components/organization-select/organization.component";
import { NotifyService } from "~/src/app/services/notify.service";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import { PostTemplateSystemTypesForSelect } from "~/src/app/modules/social-media-post/social-media-post.options";
import Utils from "~/src/app/core/utils";
import { CoreConfig } from "~/src/app/core/core.config";
import { socialSiteAppearance } from "../../social-media-post/social-media-platforms-config";
import { ActivatedRoute } from "@angular/router";
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';

//import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { IRelatedUser } from "../../users/users/users.interface";
import { UsersController } from "../../users/users/users.component";
import { POST_STATUS_APPROVED, POST_STATUS_FAILED, POST_STATUS_INACTIVE, POST_STATUS_PARTIALLY_FAILED, POST_STATUS_POSTED } from "../../social-media-post/social-media-post.constant";
import { SocialSiteController } from "~/src/app/components/social-site-select/social-site.component";
import { SOCIAL_MEDIA_TYPE_GMB } from "~/src/app/core/constants";
import {
    EmailNotificationManagerComponent
} from '~/src/app/modules/users/profile/email-notification-manager/email-notification-manager.component';
import { OpenModalService } from '~/src/app/modules/social-media-post/open-modal.service';
import { DialogLoaderComponent } from "~/src/app/components/dialog-loader/dialog-loader.component";
import { TemplateFolderActionsService } from "~/src/app/modules/posts/template-folder-actions.service";
import { TemplateFolderMinimalInterface } from "~/src/app/modules/posts/template-folder.interface";
// import { ComponentAbstract } from '~/src/app/services/component.abstract';
import { TemplateFolderActions } from "../template-folder-actions";
export interface TemplateFiltersInterface {
    searchValue: string;
    order: string;
    tags: string;
    categories: string;
    status: string;
    activeFrom: string;
    activeFromTo: string;
    socialSiteID: string;
    organizationIDs?: number[];
    createdBy: number[];
}

@Component({
    selector: "smd-post-template-filters",
    templateUrl: "./post-template-filters.component.html",
    styleUrls: ["./post-template-filters.component.scss"],
})
export class PostTemplateFiltersComponent extends TemplateFolderActions implements OnInit, OnDestroy {
    // Form controls
    form: FormGroup = new FormGroup({
        searchValueFormControl: new FormControl({ value: "", disabled: false }),
        orderByFormControl: new FormControl({ value: null, disabled: false }),
        tagsFormControl: new FormControl({ value: null, disabled: false }),
        categoriesFormControl: new FormControl({
            value: null,
            disabled: false,
        }),
        folderFormControl: new FormControl({
            value: null,
            disabled: false,
        }),
        statusFormControl: new FormControl({ value: null, disabled: false }),
        dateFromFormControl: new FormControl({ value: null, disabled: false }),
        dateToFormControl: new FormControl({ value: null, disabled: false }),
        definedDatesFormControl: new FormControl({
            value: null,
            disabled: false,
        }),
        createdByFormControl: new FormControl({ value: null, disabled: false }),
        socialSiteSelectFormControl: new FormControl({ value: [], disabled: false }),
        organizationID: new FormControl({ value: null, disabled: true }),
        socialChannel: new FormControl(null),
        templateSystemType: new FormControl({ value: null, disabled: true }),
        foldertypeFormControl: new FormControl({
            value: null,
            disabled: false,
        }),
    });

    @ViewChild("organizationSelect", { static: true })
    organizationSelect: MatSelect;
    @ViewChild("socialChannelSelect", { static: true })
    socialChannelSelect: MatSelect;
    @ViewChild("categoryFilterSelect", { static: true })
    categoryFilterSelect: MatSelect;
    @ViewChild("tagFilterSelect", { static: true }) tagFilterSelect: MatSelect;
    @ViewChild("orderbyFilterSelect", { static: true })
    orderbyFilterSelect: MatSelect;
    @ViewChild("definedDatesFilterSelect", { static: true })
    definedDatesFilterSelect: MatSelect;
    @ViewChild("keywordFilterSelect", { static: true })
    keywordFilterSelect: MatInput;
    @ViewChild("statusFilterSelect", { static: true })
    statusFilterSelect: MatSelect;
    @ViewChild("dateFromFilterSelect", { static: true })
    dateFromFilterSelect: MatInput;
    @ViewChild("dateToFilterSelect", { static: true })
    dateToFilterSelect: MatInput;
    @ViewChild("postTemplateSystemTypeSelect", { static: true })
    postTemplateSystemTypeSelect: MatInput;
    @ViewChild("folderFilterSelect", { static: true })
    folderFilterSelect: MatInput;
    @ViewChild("allSelect", { static: true }) allSelect: MatOption;
    @ViewChild("f", { static: true }) myNgForm;
    @Output("filterChange")
    filterChange: EventEmitter<TemplateFiltersInterface> = new EventEmitter<TemplateFiltersInterface>();
    @Output("resetSchedule")
    resetSchedule: EventEmitter<TemplateFiltersInterface> = new EventEmitter<TemplateFiltersInterface>();
    @Input() resetForm: Subject<any>;
    @Input() isNeedSocialChannels = true;
    @Input() createdByNeeded = true;
    @Input() entityType: "post" | "postTemplate" = "post";
    @Input() isModal = false;
    @Input() isAdminMode = false;
    @Input("loadedFrom") loadedFrom = null;
    @Input() tagClickEvent: Observable<any>;
    @Input() filterClickEvent: Observable<any>;

    // Components
    _tagsComponent: TagsComponent;
    _categoriesComponent: CategoriesComponent;
    _statusComponent: StatusComponent;
    _TemplateFolderActionsService: TemplateFolderActionsService;
    // Data arrays
    tags: string[] = [];
    @Input() relatedUsers: Array<IRelatedUser> = [];
    statuses: string[] = [];
    categories: Category[] = [];
    socialChannels = [...Configs.socials];

    postTemplateSystemTypes = Utils.lodash.cloneDeep(
        PostTemplateSystemTypesForSelect
    );

    lastSocialType;
    subs = [];
    arry=[];
    templateManagerPage = 'all';
    selectedTabindex = 0;
    folderName = '';
    typevalue = '';
    foldersOrdered: TemplateFolderMinimalInterface[] = [];
    foldersFiltered: TemplateFolderMinimalInterface[] = [];
    // foldersFiltered: any[] = []; 
    filteredUsers: IRelatedUser[] = [];

    socialSites = [];
    socialSitesFetching: boolean = false;
    folders = [];
    filterType = 'all'; 
    showSettings = false; 
    foldersid = [];
  // Handle filter type changes
  onFilterTypeChange(event: any) {
    console.log('Filter type selected:', event.value);
  }
  folderControlNames = {
    JumpToFolder: "jumpToFolder",
};
  isPopupOpen: boolean = false; 
  check1Selected: boolean = false; 
  isChecked: boolean = false;
  folderIds: string = '';  
  folderOptions = [
    { id: 1, name: 'Organization Folder', icon: 'fa-solid fa-folder-open', selected: false },
    { id: 2, name: 'User Folder', icon: 'fa-solid fa-folder-user', selected: false }
  ];
  togglePopup(): void {
    this.isPopupOpen = !this.isPopupOpen;
  }
  selectedFolderIds: string[] = []; 
  isSelected(folderId: string): boolean {
    return this.selectedFolderIds.includes(folderId);
  }
  onCheckboxChange(folderId: string, isSelected: boolean) {
    if (isSelected) {
     
      if (!this.selectedFolderIds.includes(folderId)) {
        this.selectedFolderIds.push(folderId);
      }
    } else {
     
      this.selectedFolderIds = this.selectedFolderIds.filter(id => id !== folderId);
    }
  
    if (this.selectedFolderIds.length === 2) {
        this.typevalue = 'all';
      } else if (this.selectedFolderIds.includes('organization')) {
        this.typevalue = 'organization';
      } else if (this.selectedFolderIds.includes('user')) {
        this.typevalue = 'user';
      } else {
        this.typevalue = null; 
      }
    this.filters['type'] = this.typevalue;
  
     this.getFolders();
  }
    applyFilters(): void {
      this.isPopupOpen = false;
  }
folderFormGroup = new FormGroup({
    [this.folderControlNames.JumpToFolder]: new FormControl(""),
});
    /**
     * Filters options
     *
     * @type {{limit: number; offset: number; order: string}}
     */
    constructor(
        private openModal: OpenModalService,
        private _tagsService: TagsService,
        private _categoriesService: CategoriesService,
        private _statusService: StatusService,
        private organizationController: OrganizationController,
        public partnerConfig: PartnerConfigService,
        private route: ActivatedRoute,
        public users: UsersController,
        private socialSiteController: SocialSiteController,
        //private tagClickEventSubscription: Subscription,
        public folderService: TemplateFolderActionsService,
    ) {
        super(folderService);
        //this.statusInit();
    }
    ngOnInit() {
        this.socialChannels = this.socialChannels.filter(
            (item) =>
                socialSiteAppearance?.[item.id]?.[this.loadedFrom] !== false
        );

        this.postTemplateSystemTypes = this.postTemplateSystemTypes.filter(
            (item) =>
                socialSiteAppearance?.[item.id]?.[this.loadedFrom] !== false
        );

        if (this.loadedFrom === "postTemplateModal") {
            this.tagsInit();
            this.categoriesInit();
            this.getAllFoldersForSelect();
            this.getFolders();
        }
        //this.usersInit();

        if (this.isAdminMode) {
            const index = this.postTemplateSystemTypes.findIndex(
                (type) => type.id === CoreConfig.getSystemTypes().Custom
            );

            if (index > -1) {
                this.postTemplateSystemTypes.splice(index, 1);
            }
        }

        if (!this.partnerConfig.hasConfig(PartnerPermissions.GenericTemplate)) {
            const index = this.postTemplateSystemTypes.findIndex(
                (type) => type.id === CoreConfig.getSystemTypes().Generic
            );

            if (index > -1) {
                this.postTemplateSystemTypes.splice(index, 1);
            }
        }

        const sub1 = this.form.get("socialSiteSelectFormControl").valueChanges.subscribe(
            (value) => {
                this.emitFilterChange();
            }
        );

        const sub2 = this.form.get("organizationID").valueChanges.subscribe(
            (value) => {
                this.form.get("socialSiteSelectFormControl").setValue([]);
                this.initSocialSites();
                this.emitFilterChange();
            }
        );

        const sub4 = this.form
            .get("socialChannel")
            .valueChanges.subscribe((value) => {
                this.form.get("socialSiteSelectFormControl").setValue([]);
                this.initSocialSites();
                this.emitFilterChange();
            });

        if (this.resetForm) {
            this.resetForm.subscribe((paramCallback) => {
                if (typeof paramCallback === "function") {
                    paramCallback();
                } else {
                    this.categoryFilterSelect.value = null;
                    this.tagFilterSelect.value = null;
                    this.orderbyFilterSelect.value = null;
                    this.statusFilterSelect.value = null;
                    this.definedDatesFilterSelect = null;
                    this.keywordFilterSelect.value = "";
                    this.organizationSelect.value = null;
                    this.socialChannelSelect.value = null;
                    this.postTemplateSystemTypeSelect.value = null;
                    this.form.get("socialSiteSelectFormControl").setValue([]);
                }
            });
        }

        const sub5 = this.tagClickEvent?.subscribe(
            (tag: string) => {
//                 let currentSearchValue = !!this.form.get("searchValueFormControl").value ? this.form.get("searchValueFormControl").value.trim() : "";
//                 let newSearchValue = "";
//                 if (currentSearchValue && currentSearchValue.includes(tag + ",")) {
//                     newSearchValue = currentSearchValue.replace(tag + ",", "");
//                 } else if (currentSearchValue && currentSearchValue.charAt(currentSearchValue.length - 1) == ",") {
//                     newSearchValue = currentSearchValue + " " + tag + ", ";
//                 } else if (currentSearchValue) {
//                     newSearchValue = currentSearchValue + ", " + tag + ", ";
//                 } else {
//                     newSearchValue = tag + ", ";
//                 }
// this.form.get("searchValueFormControl").setValue(newSearchValue);
//                 this.emitFilterChange();

                // this.form.get("tagsFormControl").setValue([tag]);
                // this.emitFilterChange();
                this.arry=[];
                if(this.form.get("tagsFormControl").value){
                // this.arry.push(this.form.get("tagsFormControl").value);
                this.arry=this.form.get("tagsFormControl").value;
            }
            if(this.arry.indexOf(tag) != -1)
                {  
                const index = this.arry.indexOf(tag);
                const x = this.arry.splice(index, 1);
                }else{
                    this.arry.push(tag);
                }
                this.form.get("tagsFormControl").setValue(this.arry);
                this.emitFilterChange();
            }
        );

        const sub6 = this.filterClickEvent?.subscribe(
            () => {
                this.statusInit();
                this.categoriesInit();
                this.updateFilteredUsers();
                if (this.loadedFrom === "templatemanager") {
                    this.tagsInit();
                    this.getAllFoldersForSelect();
                }
            }
        );

        const sub7 = this.form.controls['organizationID'].valueChanges.subscribe(
            () => {
                this.updateFilteredUsers();
            }
        );

        this.subs.push(sub1, sub2, sub4);
        if (sub5) this.subs.push(sub5);
        if (sub6) this.subs.push(sub6);
        if (sub7) this.subs.push(sub7);

        this.initSocialSites();

        if (this.folderIds) {
            this.form.get("foldertypeFormControl").setValue(this.folderIds)
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }

    /**
     * Emit filter change
     */
    @Debounce(500)
    emitFilterChange() {
        this.filterChange.emit(this.filterValues);
    }

    @Debounce(1000)
    keywordChange() {
        this.filterChange.emit(this.filterValues);
    }

    /**
     * Get filter values as object
     *
     * @return {TemplateFiltersInterface}
     */
    get filterValues(): TemplateFiltersInterface {
        // innentől kezdve nincs null és üres string filter érték.
        const typeConversion = function (input, toNumber?: boolean) {
            if (input === null) {
                return null;
            }

            if (typeof input === "string") {
                if (toNumber) {
                    return Number(input.trim());
                } else {
                    return input.trim();
                }
            }

            if (typeof input === "object" && input instanceof Object) {
                if (toNumber) {
                    return Object.keys(input).length ? JSON.stringify(input.map((item) => Number(item))) : null;
                }
                return Object.keys(input).length ? JSON.stringify(input) : null;
            }

            if (typeof input === "object" && input instanceof Array) {
                if (toNumber) {
                    return input.length ? JSON.stringify(input.map((item) => Number(item))) : null;
                }
                return input.length ? JSON.stringify(input) : null;
            }

            return input;
        };
         this.folderIds = this.form.get("folderFormControl").value;
        const result = {
            searchValue: typeConversion(
                this.form.get("searchValueFormControl").value
            ),
            order: typeConversion(this.form.get("orderByFormControl").value),
            tags: typeConversion(this.form.get("tagsFormControl").value),
            categories: typeConversion(
                this.form.get("categoriesFormControl").value
            ),
            folderID: typeConversion(
                this.folderIds,true
            ),
            status: typeConversion(this.form.get("statusFormControl").value),
            activeFrom: typeConversion(
                DateHelper.convertSingleDateToStartEndDate(
                    this.form.get("dateFromFormControl").value,
                    false
                )
            ),
            activeFromTo: typeConversion(
                DateHelper.convertSingleDateToStartEndDate(
                    this.form.get("dateToFormControl").value,
                    true
                )
            ),
            socialSiteID: typeConversion(
                this.form.get("socialSiteSelectFormControl").value
            ),
            systemType: this.form.get("templateSystemType").value,
            socialType: this.form.get("socialChannel").value,
            createdBy: typeConversion(
                this.form.get("createdByFormControl").value, true
            ),
        };
        this.lastSocialType = result.socialType;
        if (this.form.get("organizationID").enabled) {
            result["organizationIDs"] =
                this.form.get("organizationID").value || [];
        }

        return result;
    }

    /**
     * Tags init and get items
     */
    tagsInit() {
        if (!this._tagsComponent) {
            this._tagsComponent = new TagsComponent(this._tagsService);
        }

        if (this.isAdminMode) {
            this._tagsComponent.filters["type"] = "generic";
        } else {
            delete this._tagsComponent.filters["type"];
        }

        this._tagsComponent.getItems(({ tags }: { tags: any[] }) => {
            this.tags = Helpers.orderBy(tags, "name");
        });
    }

    /**
     * Categories init and get items
     */
    categoriesInit() {
        if (!this._categoriesComponent) {
            this._categoriesComponent = new CategoriesComponent(
                this._categoriesService
            );
        }

        if (this.isAdminMode) {
            this._categoriesComponent.filters["type"] = "generic";
        } else {
            delete this._categoriesComponent.filters["type"];
        }

        this._categoriesComponent.getItems(
            ({ categories }: { categories: any[] }) => {
                this.categories = Helpers.orderBy(categories, "name");
            }
        );
    }
     /**
     * Categories init and get items
     */
     getAllFoldersForSelect() {
        const filters = {
            myFolders: this.templateManagerPage === 'custom' ? true : false,
        };

        this.folderService.getAllForSelect()
            .then((response: { folders: TemplateFolderMinimalInterface[] }) => {
                this.foldersOrdered = response.folders;
                this.folders = this.foldersOrdered.slice();
            }).catch((error) => {
                console.error(error);
            });
    }

    openFolder(folderID: number) {
        this.folderFormGroup.get(this.folderControlNames.JumpToFolder).setValue("");
    }

    closeFolder(folderID: number) {
        this.folderFormGroup.get(this.folderControlNames.JumpToFolder).setValue("");
    }

    getFolderName(folderID) {
        const folder = this.foldersOrdered.find((folder) => folder.folderID == folderID);

        return folder ? folder.name : "";
    }

    filterFolders(value: string) {
        return this.foldersOrdered.filter(folder =>
            folder.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    filter(eventType?: string) {
        let filterValue = this.form.get("folderID").value;
        if (eventType && eventType === 'focus') {
            filterValue = '';
        }
        // this.foldersFiltered = [];
        this.folders = this.filterFolders(filterValue);
        this.emitFilterChange();
    }


    updateFilteredUsers() {
        const organizationIDs = this.form.get("organizationID").value;
        if (organizationIDs && organizationIDs.length) {
            this.filteredUsers = this.relatedUsers.filter((user) => {
                let userOrgIDs = (user.organizationIDs.split(',') || []).map(Number);
                return organizationIDs.some((orgID) => userOrgIDs.includes(orgID));
            });
        } else {
            this.filteredUsers = this.relatedUsers.filter((user) => {
                return true;
            });
        }

        // order by name
        /* this.filteredUsers = this.filteredUsers.map((user) => user); */
        this.filteredUsers = Helpers.orderBy(this.filteredUsers, "name");
    }

    /**
     * @description Status init and get items
     */
    statusInit() {
        if (!this._statusComponent) {
            this._statusComponent = new StatusComponent(this._statusService);
        }

        this._statusComponent.getItems(({ statuses }: { statuses: any[] }) => {
            this.statuses = statuses;

            if (this.entityType !== "post") {
                delete this.statuses[POST_STATUS_POSTED];
                delete this.statuses[POST_STATUS_INACTIVE];
                delete this.statuses[POST_STATUS_APPROVED];
                delete this.statuses[POST_STATUS_FAILED];
                delete this.statuses[POST_STATUS_PARTIALLY_FAILED];
                this.statuses["active"] =
                    LanguageService.getLine("post.status.active");
                this.statuses = Helpers.orderBy(this.statuses);
            }
        });
    }

    setDatePickerValues() {
        const value = this.form.get("definedDatesFormControl").value;
        let startDate = null;
        let endDate = null;
        if (value > 0) {
            startDate = moment().format(BASE_DATE_FORMAT);
            endDate = moment().add(value, "days").format(BASE_DATE_FORMAT);
        }

        this.form.get("dateFromFormControl").setValue(startDate);
        this.form.get("dateToFormControl").setValue(endDate);

        this.emitFilterChange();
    }

    reset() {
        this.myNgForm.resetForm();
        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        if (
            "status" in this.route.snapshot.data &&
            this.route.snapshot.data.status
        ) {
            this.form
                .get("statusFormControl")
                .setValue([this.route.snapshot.data.status]);
        }

        this.emitFilterChange();
        this.resetSchedule.emit(this.filterValues);
    }

    private initSocialSites() {
        this.socialSitesFetching = true;

        const organizationIDs = this.form.get("organizationID").value;

        const filters = {}

        if (this.form.get("socialChannel").value) {
            filters["socialType"] = this.form.get("socialChannel").value;
        }

        if (!organizationIDs || !organizationIDs.length) {
            this.organizationController.service.getAll().then((response) => {
                const orgIDs = response.organizations.map(org => org.organizationID);
                this.socialSiteController
                    .getItemsByIDs("organizationID", orgIDs, filters)
                    .then((response: any) => {
                        this.socialSites = response.socialSites;
                    }).finally(() => {
                        this.socialSitesFetching = false;
                });
            });
            return;
        }

        this.socialSiteController
            .getItemsByIDs("organizationID", organizationIDs, filters)
            .then((response: any) => {
                this.socialSites = response.socialSites;
            }).finally(() => {
                this.socialSitesFetching = false;
        });
    }
    
   getFolders() {
     const loader = this.openModal.loader(DialogLoaderComponent);
     const defaultFilters = {
       myFolders: this.templateManagerPage === 'custom' ? true : false,
     };
 
     if (!this.filters['orderBy']) {
        this.filters['orderBy'] = 'createdAt-desc';
     }
 
     if (this.filters['order']) {
       delete this.filters['order'];
     }
 
     this.getItems(
        (foldersResponse) => {
          loader.close();
          // Check if foldersResponse has the expected structure
          if (foldersResponse?.folders && Array.isArray(foldersResponse.folders)) {
            this.folders = foldersResponse.folders;
              // Set foldertypeFormControl value to the folderID of the first folder
              if (this.typevalue) {
                  console.log(foldersResponse.folders[0]?.folderID);
                  
                this.form.get("foldertypeFormControl").setValue(foldersResponse.folders[0]?.folderID || null);
              }
           
          } else {
            console.error('Unexpected response structure:', foldersResponse);
          }
          this.emitFilterChange();
        },
        () => {
          loader.close();
        }
      );
      
   }

}
